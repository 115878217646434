module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src164584098/src/parkify-marketing-site/src/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","name":"Parkify - Maximize your parking revenue","short_name":"Parkify","start_url":"/","background_color":"#f7f0eb","theme_color":"#28ffbf","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b588ca42da2909fc694327f1194afa31"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src164584098/src/parkify-marketing-site","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../plugins/gatsby-plugin-aws-rum/gatsby-browser.js'),
      options: {"plugins":[],"appId":"c97a5958-2851-40c8-a623-28c451a3cdad","appVersion":"1.0.0","appRegion":"us-east-1","config":{"sessionSampleRate":1,"disableAutoPageView":true,"guestRoleArn":"arn:aws:iam::079904312684:role/RUM-Monitor-us-east-1-079904312684-1448040530661-Unauth","identityPoolId":"us-east-1:9a09adb4-564e-4c5d-bd42-3df9ee5b80bf","endpoint":"https://dataplane.rum.us-east-1.amazonaws.com","telemetries":["performance","errors","http"],"allowCookies":true,"enableXRay":false}},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
