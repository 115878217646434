import { createContext, useState } from 'react';
import {
    Alert,
    AlertIcon,
    AlertDescription,
    Input,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    FormControl,
    FormLabel,
    Text,
    Stack
} from '@chakra-ui/react'
import { useForm } from "react-hook-form";
export const InboundSalesContext = createContext()

const formApiUrl = (portalId, formGuid) => `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`

const InboundSalesDrawer = ({ isOpen, onClose, portalId, formGuid }) => {
    const [apiError, setApiError] = useState()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful, isSubmitting, isValid, isValidating }
    } = useForm({ mode: 'onBlur' });
    const closeAndReset = () => {
        reset()
        setApiError()
        onClose()
    }
    const onSubmit = async (data) => {
        const formFields = Object.entries(data).map(([name, value]) => ({ name, value }))
        const resp = await fetch(formApiUrl(portalId, formGuid), {
            method: "POST",
            body: JSON.stringify({
                fields: formFields
            }),
            headers: { "Content-Type": "application/json" }
        })
        if (!resp.ok) {
            const errBody = await resp.json()
            const err = new Error()
            err.message = errBody;
            throw err;
        }
    }
    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={closeAndReset}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader pt={10}>
                {isSubmitSuccessful && !apiError
                    ? "Thank you"
                    : "How should we contact you?"
                }
                    </DrawerHeader>
                <DrawerBody>
                    {isSubmitSuccessful && !apiError
                        ? <Text>You'll hear from us soon.</Text>
                        : (<form
                            id="form"
                            onSubmit={handleSubmit(async (data, e) => {
                                e.preventDefault();
                                try {
                                    await onSubmit(data);
                                } catch (err) {
                                    setApiError(err.message)
                                }
                            })}
                        >
                            <Stack>
                                <FormControl isRequired isInvalid={errors.firstname} isDisabled={isSubmitting}>
                                    <FormLabel>First Name</FormLabel>
                                    <Input type='string' {...register("firstname", { required: true })} autocomplete="given-name" />
                                </FormControl>
                                <FormControl isRequired isInvalid={errors.lastname} isDisabled={isSubmitting}>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input type='string'{...register("lastname", { required: true })} autocomplete="family-name" />
                                </FormControl>
                                <FormControl isRequired isInvalid={errors.phone} isDisabled={isSubmitting}>
                                    <FormLabel>Phone Number</FormLabel>
                                    <Input type='tel' {...register("phone", { required: true })} autocomplete="tel" />
                                </FormControl>
                                <FormControl isRequired isInvalid={errors.email} isDisabled={isSubmitting}>
                                    <FormLabel>Email</FormLabel>
                                    <Input type='email' {...register("email", { required: true })} autocomplete="email" />
                                </FormControl>
                                <FormControl isRequired isInvalid={errors.company} isDisabled={isSubmitting}>
                                    <FormLabel>Company Name</FormLabel>
                                    <Input type='string' {...register("company", { required: true })} autocomplete="organization" />
                                </FormControl>
                                <FormControl isRequired isInvalid={errors.address} isDisabled={isSubmitting}>
                                    <FormLabel>Facility Address</FormLabel>
                                    <Input type='string' {...register("address", { required: true })} autocomplete="street-address" />
                                </FormControl>
                            </Stack>
                        </form>)
                    }
                </DrawerBody>
                <DrawerFooter>
                    <Stack w="100%">
                        <Stack direction={["column", "row"]} justify="start">
                            <Button 
                                variant='outline'
                                onClick={closeAndReset} 
                                w={["100%", "auto"]}>
                                Close
                            </Button>
                            <Button
                                w={["100%", "auto"]}
                                colorScheme="brand"
                                textColor="black"
                                form="form"
                                type="submit"
                                isDisabled={!isValid || (isSubmitSuccessful & !apiError)}
                                isLoading={isValidating || isSubmitting}
                            >Submit</Button>
                        </Stack>

                        {apiError
                            ? (<Alert status='error'>
                                <AlertIcon />
                                <AlertDescription>Try submitting again later.</AlertDescription>
                                <Text>{JSON.stringify(apiError)}</Text>
                            </Alert>)
                            : null
                        }
                    </Stack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default InboundSalesDrawer;