import { useContext } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon
} from '@chakra-ui/icons';
import { InboundSalesContext } from "./InboundSales";

export default function Nav() {
    const { isOpen, onToggle } = useDisclosure();
    const { onOpen } = useContext(InboundSalesContext);
    return (
        <Box as="header">
            <Flex
                bg={useColorModeValue('gray.100', 'gray.900')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}>
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
                    <Link
                        as={GatsbyLink}
                        to={`/`}
                        textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                        fontFamily={'heading'}
                        fontWeight={800}
                        color={useColorModeValue('gray.800', 'white')}
                        _hover={{ color: 'brand.500' }}
                    >
                        Parkify
                    </Link>
                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    <Button
                        display={{ base: 'none', md: 'inline-flex' }}
                        colorScheme="brand"
                        textColor="black"
                        onClick={onOpen}>
                        Get Started Today!
                    </Button>
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const popoverContentBgColor = useColorModeValue('gray.50', 'gray.800');
    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover
                        trigger={'hover'}
                        placement={'bottom-start'}
                    >
                        {({ isOpen, onClose }) => {
                            const buttonProps = navItem.href ? { as: GatsbyLink, to: navItem.href } : {}
                            return (
                                <>
                                    <PopoverTrigger>
                                        <Button
                                            {...buttonProps}
                                            variant={"link"}
                                            ph={2}
                                            fontSize={'sm'}
                                            fontWeight={500}
                                            color={linkColor}
                                            _hover={{
                                                textDecoration: 'none',
                                                color: "purple.500",
                                            }}>
                                            {navItem.label}
                                            {navItem.children ? <Icon
                                                as={ChevronDownIcon}
                                                transition={'all .2s ease-in-out'}
                                                transform={isOpen ? 'rotate(180deg)' : ''}
                                                w={6}
                                                h={6}
                                            /> : null}
                                        </Button>
                                    </PopoverTrigger>

                                    {navItem.children && (
                                        <PopoverContent
                                            border={0}
                                            boxShadow={'xl'}
                                            bg={popoverContentBgColor}
                                            p={4}
                                            rounded={'xl'}
                                            minW={'sm'}
                                            onClick={onClose}
                                        >
                                            <Stack>
                                                {navItem.children.map((child) => (
                                                    <DesktopSubNav key={child.label} {...child} />
                                                ))}
                                            </Stack>
                                        </PopoverContent>
                                    )}
                                </>
                            )
                        }
                        }
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            as={GatsbyLink}
            to={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('gray.100', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'gold.500' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'gold.500'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('gray.50', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();
    const buttonProps = href ? { as: GatsbyLink, to: href } : {}
    return (
        <Stack spacing={4} >
            <Flex
                onClick={children && onToggle}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                    color: "purple.500"
                }}>
                <Button
                    {...buttonProps}
                    variant="link"
                    key={label}
                    py={2}
                    fontWeight={600}
                    color={'grat.600'}
                >{label}</Button>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={0}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link as={GatsbyLink} key={child.label} py={1} to={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};


const NAV_ITEMS = [
    {
        label: 'Features',
        children: [
            {
                label: 'Custom Design and Branding',
                subLabel: `Our designers make your parking facility stand out from your competitors.`,
                href: '/features#custom-design-and-branding',
            },
            {
                label: 'Built for Mobile',
                subLabel: `Mobile optimized websites ensure that drivers get to your facility without delay.`,
                href: '/features#built-for-mobile',
            },
            {
                label: 'SEO Built-In',
                subLabel: `SEO, JSON Structured Data, Open-Graph Tags, XML site maps. We make sure that your facility is well integrated into search, maps, and other internet services. `,
                href: '/features#seo'
            },
            {
                label: 'Local Business Listings',
                subLabel: `Accurate hours of operation and driving directions are crutial for drivers using Google Maps.`,
                href: '/features#local-business-listings'
            }
        ],
    },
    {
        label: "Solutions For",
        children: [
            {
                label: 'Commercial Real Estate',
                subLabel: "Garages, open-surface lots, mixed-use lots.",
                href: "/commercial-real-estate"
            },
            {
                label: 'Parking Operators',
                subLabel: "Get Parkify for your whole portfolio.",
                href: "/parking-operators"
            },
            {
                label: 'Municipalities',
                subLabel: "On-street parking and off-street parking",
                href: "/municipalities"
            },
            {
                label: 'Campuses',
                subLabel: "Universities, private campuses, and event spaces.",
                href: "/campuses"
            }
        ]
    },
    {
        label: 'Pricing',
        href: '/pricing'
    }
];
