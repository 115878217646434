import { Link as GatsbyLink } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import {
    Box,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export default function Footer() {
    return (
        <Box
            as="footer"
            bg={useColorModeValue('gray.100', 'gray.900')}
            color={useColorModeValue('gray.600', 'white')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
                    spacing={8}>
                    <Stack spacing={6}>
                        <Box>
                            <StaticImage placeholder="blurred" height={64} src={"./images/icon.svg"} alt="Parkify Logo" />
                        </Box>
                        <Text fontSize={'sm'}>
                            © 2022 Parkify
                        </Text>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Features</ListHeader>
                        <Link as={GatsbyLink} to={'/features#custom-design-and-branding'}>Custom Design and Branding</Link>
                        <Link as={GatsbyLink} to={'/features#built-for-mobile'}>Built for mobile</Link>
                        <Link as={GatsbyLink} to={'/features#seo'}>SEO</Link>
                        <Link as={GatsbyLink} to={'/features#local-business-listing'}>Local Business Listing</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Solutions For</ListHeader>
                        <Link as={GatsbyLink} to={'/commercial-real-estate'}>Commercial Real Estate</Link>
                        <Link as={GatsbyLink} to={'/parking-operators'}>Parking Operators</Link>
                        <Link as={GatsbyLink} to={'/municipalities'}>Municipalities</Link>
                        <Link as={GatsbyLink} to={'/campuses'}>Campuses</Link>

                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Pricing</ListHeader>
                        <Link as={GatsbyLink} to={'/pricing'}>Plans</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Company</ListHeader>
                        <Link as={GatsbyLink} to={'/about#the-mission'}>Mission</Link>
                        <Link as={GatsbyLink} to={'/about#the-team'}>Team</Link>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}
