import { AwsRum } from 'aws-rum-web';

let rumClient;

export function onClientEntry(_, options) {
    rumClient = new AwsRum(options.appId, options.appVersion, options.appRegion, options.config);
}

export function onRouteUpdate({ location }) {
    const params = new URLSearchParams(location.search)
    const pageTags = params.getAll("utm_source");
    if (rumClient) {
        rumClient.recordPageView({ pageId: location.pathname + location.hash, pageTags });
    }
}
