import { extendTheme } from "@chakra-ui/react"
const theme = {
    colors: {
        "brand": {
            50: "#eafff9",
            100: "#d4fff2",
            200: "#a9ffe5",
            300: "#7effd9",
            400: "#53ffcc",
            500: "#28ffbf",
            600: "#20cc99",
            700: "#189973",
            800: "#10664c",
            900: "#083326",
        },
        "purple": {
            50: "#f9eaff",
            100: "#f2d4ff",
            200: "#e5a9ff",
            300: "#d97eff",
            400: "#cc53ff",
            500: "#bf28ff",
            600: "#9920cc",
            700: "#731899",
            800: "#4c1066",
            900: "#260833",
        },
        "gold": {
            50: "#fff9ea",
            100: "#fff2d4",
            200: "#ffe5a9",
            300: "#ffd97e",
            400: "#ffcc53",
            500: "#ffbf28",
            600: "#cc9920",
            700: "#997318",
            800: "#664c10",
            900: "#332608",
        },
    },
    components: {
        Button: {
            baseStyle: {
            },
            defaultProps: {
                fontColor: 'black',
                size: 'lg', // default is md
            },
        },
    },
}

export default extendTheme(theme)