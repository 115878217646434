exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-campuses-jsx": () => import("./../../../src/pages/campuses.jsx" /* webpackChunkName: "component---src-pages-campuses-jsx" */),
  "component---src-pages-commercial-real-estate-jsx": () => import("./../../../src/pages/commercial-real-estate.jsx" /* webpackChunkName: "component---src-pages-commercial-real-estate-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-municipalities-jsx": () => import("./../../../src/pages/municipalities.jsx" /* webpackChunkName: "component---src-pages-municipalities-jsx" */),
  "component---src-pages-parking-operators-jsx": () => import("./../../../src/pages/parking-operators.jsx" /* webpackChunkName: "component---src-pages-parking-operators-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */)
}

