import { Box, useDisclosure } from "@chakra-ui/react"
import Nav from "./nav";
import Footer from "./footer";
import InboundSalesDrawer, { InboundSalesContext } from "./InboundSales";

const portalId = 22681177;
const formGuid = 'bf2c6598-a39b-4138-b5ab-2a98b175d5b6';

const Layout = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <InboundSalesContext.Provider value={{ onOpen, onClose }}>
            <Box>
                <InboundSalesDrawer isOpen={isOpen} onClose={onClose} portalId={portalId} formGuid={formGuid}/>
                <Nav />
                {children}
                <Footer />
            </Box>
        </InboundSalesContext.Provider>
    )
}

export default Layout;